import axios from "axios";
const state = {
  cars: [],
  cartitle: "",
  selectedGrade: "",
  selectedGradeObj: {},
  sort_by: "lth", //for sorting low to high
};

const getters = {
  getallCars: (state) => state.cars,
  getGrade: (state) => state.selectedGrade,
  getGradObj: (state) => state.selectedGradeObj,
  getSort: (state) => state.sort_by,
};

const actions = {
  setSelectedObj({ commit, dispatch, rootState }, data) {
    var parsedobj = JSON.parse(JSON.stringify(rootState));
    let selectedCarGrade = parsedobj.Cars.cars.grades[data[1]];

    const dataExteriror = {
      ...selectedCarGrade.colors[0],
      ext_path: selectedCarGrade.wheel[0].path,
    };

    if (
      !parsedobj.Pages.selectedExtColors.name ||
      parsedobj.Pages.selectedExtColors.ext_path !==
        selectedCarGrade.wheel[0].path
    ) {
      dispatch("setSelectedColor", dataExteriror, { root: true });
    }

    dispatch("setSelectedIntColor", selectedCarGrade.interior[0], {
      root: true,
    });

    dispatch("changeAlreadySelected", null, { root: true });

    commit("setGradeObj", selectedCarGrade);
    commit("changePage", data[0], { root: true });
    commit("setSelectedWheel", selectedCarGrade.wheel[0], { root: true });

    const sgrade = selectedCarGrade.Version_Label;
    const smodel = parsedobj.Cars.cars.model.model.toLowerCase();
    var scolorExterior = dataExteriror.key;
    var scolorinterior = selectedCarGrade.interior[0].key;
    const urlpath =
      "#" +
      smodel.replace(/ /g, "_") +
      "/" +
      sgrade.replace(/ /g, "_") +
      "/" +
      scolorExterior.replace(/ /g, "_") +
      "/" +
      scolorinterior +
      "/" +
      1;
    document.location.href = urlpath;
    commit("setSummaryUrl", urlpath);
  },

  //action for changing grade object  when the different model interior is choosed
  SelectedObj({ commit, dispatch, rootState }, data) {
    var parsedobj = JSON.parse(JSON.stringify(rootState));
    let selectedCarGrade = parsedobj.Cars.cars.grades[data];
    var dataExteriror = parsedobj.Pages.selectedExtColors;

    var ssdata = selectedCarGrade.colors.filter((item) => {
      return item.key == parsedobj.Pages.selectedExtColors.key;
    });

    if (ssdata.length == 0) {
      dataExteriror = {
        ...selectedCarGrade.colors[0],
        ext_path: selectedCarGrade.wheel[0].path,
      };
    }
    (dataExteriror.ext_path = selectedCarGrade.wheel[0].path),
      dispatch("setSelectedColor", dataExteriror, { root: true });

    dispatch("setSelectedIntColor", selectedCarGrade.interior[0], {
      root: true,
    });

    // const dataExteriror = {
    //   ...selectedCarGrade.colors[0],
    //   ext_path: selectedCarGrade.wheel[0].path,
    // };
    // dispatch("setSelectedColor", dataExteriror, { root: true });

    commit("setSelectedWheel", selectedCarGrade.wheel[0], { root: true });
    commit("setGradeObj", selectedCarGrade);
  },

  SelectedObjWheel({ commit, dispatch, rootState }, da) {
    var data = da[0];
    var wheelkey = da[1];

    var parsedobj = JSON.parse(JSON.stringify(rootState));
    let selectedCarGrade = parsedobj.Cars.cars.grades[data];

    var dataExteriror = parsedobj.Pages.selectedExtColors;

    var ssdata = selectedCarGrade.colors.filter((item) => {
      return item.key == parsedobj.Pages.selectedExtColors.key;
    });

    if (ssdata.length == 0) {
      dataExteriror = {
        ...selectedCarGrade.colors[0],
        ext_path: selectedCarGrade.wheel[0].path,
      };
    }

    dataExteriror.ext_path = selectedCarGrade.wheel[0].path;

    var wwdata = selectedCarGrade.wheel.filter((item) => {
      return item.key == wheelkey;
    });

    if (wwdata.length > 0) {
      dataExteriror.ext_path = wwdata[0].path;
    }

    dispatch("setSelectedColor", dataExteriror, { root: true });

    dispatch("setSelectedIntColor", selectedCarGrade.interior[0], {
      root: true,
    });

    commit("setGradeObj", selectedCarGrade);
  },
  reset({ rootState, commit }) {
    var parsedobj = JSON.parse(JSON.stringify(rootState));
    let sgrade = Object.keys(parsedobj.Cars.cars.grades)[0];
    let selectedCarGrade = parsedobj.Cars.cars.grades[sgrade];

    const dataExteriror = {
      ...selectedCarGrade.colors[0],
      ext_path: selectedCarGrade.wheel[0].path,
    };

    let smodel = parsedobj.Cars.cars.model.model;

    var scolorExterior = dataExteriror.key;
    var scolorinterior = selectedCarGrade.interior[0].key;
    commit("setSelectedWheel", selectedCarGrade.wheel[0], { root: true });

    const urlpath =
      "#" +
      smodel.replace(/ /g, "_") +
      "/" +
      sgrade.replace(/ /g, "_") +
      "/" +
      scolorExterior.replace(/ /g, "_") +
      "/" +
      scolorinterior.replace(/ /g, "_");
    document.location.href = urlpath;
    commit("setSummaryUrl", urlpath, { root: true });
  },

  getCarName() {
    let uriParams = document.location.href.split("#");
    let uriParam = uriParams[1];
    if (!uriParam) return "";
    uriParams = uriParam.split("/");
    return uriParams[0] ? uriParams[0] : "";
  },

  async fetchCars({ commit, dispatch, rootState }) {
    const response = await axios.get(
      `https://configurator.assetrepository.me/json/data.json`
    );
    let selectedGradeByDefault = "";
    let model = "patrol";
    let uriParams = document.location.href.split("#");
    let uriParam = uriParams[1];
    var page = 0;
    if (uriParam) {
      uriParams = uriParam.split("/");
      model = uriParams[0] ? uriParams[0] : "";
      var grade = uriParams[1] ? uriParams[1] : "";
      var extcol = uriParams[2] ? uriParams[2] : "";
      var intcol = uriParams[3] ? uriParams[3] : "";
      page = uriParams[4] ? uriParams[4] : "";
    }

    if (page == "") {
      page = 0;
      commit("changePage", page, { root: true });
    } else {
      commit("changePage", page, { root: true });
    }
    let res = response.data[model];

    var final = Object.entries(res.grades).filter(function(n) {
      return n[1].interior.length > 0 && n[1].wheel.length > 0;
    });

    res.grades = final.reduce((op, [key, value]) => {
      op[key] = value;
      return op;
    }, {});
    if (!grade) {
      let currGrade = [Object.keys(res.grades)[0]].toString();
      let currGradeObj = res.grades[Object.keys(res.grades)[0]];
      commit("setGrade", currGrade);
      commit("setGradeObj", currGradeObj);
      selectedGradeByDefault = currGrade;

      /** Load default data */

      let selectedCarGrade =
        response.data[model].grades[selectedGradeByDefault];

      const dataExteriror = {
        ...selectedCarGrade.colors[0],
        ext_path: selectedCarGrade.wheel[0].path,
      };
      dispatch("setSelectedColor", dataExteriror, { root: true });
      dispatch("setSelectedIntColor", selectedCarGrade.interior[0], {
        root: true,
      });

      commit("setSelectedWheel", selectedCarGrade.wheel[0], { root: true });

      commit("setCars", res);

      var parsedobj = JSON.parse(JSON.stringify(rootState));
      var smodel = parsedobj.Cars.cars.model.model.toLowerCase();
      var sgrade = selectedCarGrade.Version_Label;
      var scolorExterior = dataExteriror.key;
      var scolorinterior = selectedCarGrade.interior[0].key;

      const urlpath =
        "#" +
        smodel.replace(/ /g, "_") +
        "/" +
        sgrade.replace(/ /g, "_") +
        "/" +
        scolorExterior.replace(/ /g, "_") +
        "/" +
        scolorinterior.replace(/ /g, "_") +
        "/" +
        page;
      document.location.href = urlpath;
      commit("setSummaryUrl", urlpath, { root: true });
      /** End Load Default data  */
    } else {
      const urlpath =
        "#" + model + "/" + grade + "/" + extcol + "/" + intcol + "/" + page;
      commit("setSummaryUrl", urlpath, { root: true });

      selectedGradeByDefault = grade;
      commit("setGrade", grade);
      let currGradeObj = res.grades[grade];
      commit("setGradeObj", currGradeObj);

      /** Load selected data */

      let selectedCarGrade =
        response.data[model].grades[selectedGradeByDefault];
      var selectedExtCol = "";
      if (extcol)
        selectedExtCol = selectedCarGrade.colors.filter(function(item) {
          return item.key === extcol;
        })[0];
      else selectedExtCol = selectedCarGrade.colors[0];

      var selectIntCol = "";
      if (intcol)
        selectIntCol = selectedCarGrade.interior.filter(function(item) {
          return item.key === intcol;
        })[0];
      else selectIntCol = selectedCarGrade.interior[0];

      if (intcol)
        selectIntCol = selectedCarGrade.interior.filter(function(item) {
          return item.key === intcol;
        })[0];
      else selectIntCol = selectedCarGrade.interior[0];

      const dataExteriror = {
        ...selectedExtCol,
        ext_path: selectedCarGrade.wheel[0].path,
      };

      dispatch("setSelectedColor", dataExteriror, { root: true });
      dispatch("setSelectedIntColor", selectIntCol, {
        root: true,
      });
      commit("setSelectedWheel", selectedCarGrade.wheel[0], { root: true });

      commit("setCars", res);
      /** End Load selected data  */
    }
  },

  async getSelectedCar({ commit }, cartitle) {
    commit("selectedCar", cartitle);
  },
};

const mutations = {
  setSort: (state, data) => {
    state.sort_by = data;
  },
  setCars: (state, cars) => {
    state.cars = cars;
  },
  selectedCar: (state, title) => {
    state.cartitle = title;
  },
  setGrade: (state, grade) => {
    state.selectedGrade = grade;
  },

  setGradeObj: (state, grade) => {
    state.selectedGradeObj = grade;
  },
};

// then need to export
export default {
  state,
  getters,
  actions,
  mutations,
};
