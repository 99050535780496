<template>
  <div
    class="flex md-custom-min:flex-row md-custom:flex-col-reverse flex-wrap relative  summaryWrap"
    :class="[language != 'ar' && 'md-custom-min:pr-5']"
  >
    <section
      class="flex flex-col w-full previewWrap"
      :class="[language != 'ar' && 'md-custom-min:px-4']"
    >
      <div class="toggleContainer md-custom:hidden">
        <div class="innerr">
          <span
            class="toggleUp"
            @click="ExView()"
            v-bind:class="{ active: exterior }"
            >{{ $t("VIEW_EXTERIOR") }}</span
          >
          <span
            class="toggleDown"
            @click="ExView()"
            v-bind:class="{ active: interior }"
            >{{ $t("VIEW_INTERIOR") }}</span
          >
        </div>
      </div>
      <div class="w-full md-custom:hidden">
        <div class="w-full cursor-grab">
          <div class="In_Ex_View relative">
            <PreviewBox
              v-bind:grades="getGradObj"
              v-bind:selectedColor="selectedExtColor"
              v-if="exterior && !IsLoading"
              class="summaryPreview"
              v-bind:class="[isExpand ? 'expandHeight' : 'initialHeight']"
            />
            <div class="forInterior" v-if="interior">
              <Interior
                v-bind:grades="getGradObj"
                v-bind:selectedIntColor="selectedIntColor"
              />
            </div>
            <div
              class="block absolute  top-0"
              :class="[language != 'ar' ? 'right-0' : 'left-0']"
            >
              <button
                class="icoNissan expandBtn"
                @click="expandDiv()"
                v-bind:class="{ triggered: isTriggered }"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="specWrap">
        <ul class="w-full flex justify-between md-custom-min:mb-5 tabPills">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            @click="currentTab = index"
            :class="{ active: currentTab === index }"
            class="uppercase font-medium text-base"
          >
            {{ tab }}
          </li>
        </ul>
        <div class="tabContent">
          <div v-show="currentTab === 0">
            <div class="configurator">
              <ul class="w-full">
                <li class="w-full flex justify-between">
                  <div class="itemWrap">
                    <div class="w-full flex item">
                      <div class="left">
                        <div class="flex action">
                          <button class="edit" @click="changePage(0)"></button>
                          <span
                            class="uppercase font-semibold text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            >{{ $t("TABS.VERSION") }}</span
                          >
                        </div>
                      </div>
                      <div class="flex right">
                        <div class="action">
                          <button
                            class="icnBtn info"
                            v-on:click="featuresOpen('showFeatures')"
                          ></button>
                          <span
                            class="uppercase font-normal text-sm "
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            v-if="getGradObj"
                          >
                            {{ getGradObj.Version_Label }}</span
                          >
                        </div>
                        <div class="labelIn">
                          <div class="flex  text-sm priceIn">
                            <span
                              :class="[
                                language == 'en' ? 'order-1' : 'order-2',
                              ]"
                            >
                              {{ $t("CURRENCY") }}</span
                            >
                            <span
                              :class="[
                                language == 'en'
                                  ? 'order-2 px-1'
                                  : 'order-1 px-1',
                              ]"
                            >
                              {{
                                Number(getGradObj.price).toLocaleString()
                              }}</span
                            >
                            <span
                              :class="[
                                language == 'en' ? 'order-2' : 'order-3',
                              ]"
                              >*</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobEdit">
                    <button class="edit"><i class="fas fa-pen"></i></button>
                  </div>
                </li>
                <li class="w-full flex justify-between">
                  <div class="itemWrap">
                    <div class="w-full flex item">
                      <div class="left">
                        <div class="flex action">
                          <button class="edit" @click="changePage(1)"></button>
                          <span
                            class="uppercase font-semibold text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            >{{ $t("TABS.COLOURS") }}</span
                          >
                        </div>
                      </div>
                      <div class="flex right">
                        <div class="action">
                          <button class="icnBtn view"></button>
                          <span
                            class="uppercase font-normal text-sm ml-3"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            v-if="selectedExtColorName"
                          >
                            <span v-if="language == 'en'"
                              >{{ selectedExtColorName.name }}
                            </span>
                            <span v-else
                              >{{ selectedExtColorName.name_ar }}
                            </span>
                          </span>
                        </div>
                        <div class="labelIn">
                          <span class="uppercase font-normal text-sm">{{
                            $t("INCLUDED")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobEdit">
                    <button class="edit"><i class="fas fa-pen"></i></button>
                  </div>
                </li>
                <li class="w-full flex justify-between">
                  <div class="itemWrap">
                    <div class="w-full flex item">
                      <div class="left">
                        <div class="flex action">
                          <button class="edit" @click="changePage(2)"></button>
                          <span
                            class="uppercase font-semibold text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            >{{ $t("TABS.UPHOLSTERY") }}</span
                          >
                        </div>
                      </div>
                      <div class="flex right">
                        <div class="action">
                          <button class="icnBtn view"></button>
                          <span
                            class="uppercase font-normal text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            v-if="selectedIntColor.name"
                          >
                            <span v-if="language == 'en'">
                              {{ selectedIntColor.name }}
                            </span>
                            <span v-else> {{ selectedIntColor.name_ar }} </span>
                          </span>
                        </div>
                        <div class="labelIn">
                          <span class="uppercase font-normal text-sm">{{
                            $t("INCLUDED")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobEdit">
                    <button class="edit"><i class="fas fa-pen"></i></button>
                  </div>
                </li>
                <li class="w-full flex justify-between">
                  <div class="itemWrap">
                    <div class="w-full flex item">
                      <div class="left">
                        <div class="flex action">
                          <button class="edit" @click="changePage(3)"></button>
                          <span
                            class="uppercase font-semibold text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            >{{ $t("TABS.WHEELS") }}</span
                          >
                        </div>
                      </div>
                      <div class="flex right">
                        <div class="action">
                          <!-- <button class="icnBtn view"></button> -->
                          <span
                            class="uppercase font-normal text-sm"
                            :class="[language == 'en' ? 'ml-3' : 'mr-3']"
                            v-if="getGradObj.wheel"
                          >
                            <span v-if="language == 'en'">{{
                              getGradObj.wheel[0].name
                            }}</span>
                            <span v-else>{{
                              getGradObj.wheel[0].name_ar
                            }}</span>
                          </span>
                        </div>
                        <div class="labelIn">
                          <span class="uppercase font-normal text-sm">
                            {{ $t("INCLUDED") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobEdit">
                    <button class="edit"><i class="fas fa-pen"></i></button>
                  </div>
                </li>
              </ul>

              <div class="totalPrice flex">
                <label class="text-xs font-normal w-full flex justify-end">{{
                  $t("TOTAL_PRICE")
                }}</label>
                <span class="text-2xl justify-end font-light w-full  flex mt-1">
                  <span
                    :class="[
                      language == 'en' ? 'order-1  px-1' : 'order-2 px-1',
                    ]"
                  >
                    {{ $t("CURRENCY") }}</span
                  >
                  <span :class="[language == 'en' ? 'order-2' : 'order-1']">
                    {{ Number(getGradObj.price).toLocaleString() }}</span
                  >
                </span>
              </div>
              <div class="flex w-full justify-end my-8">
                <button
                  :class="[language != 'ar' ? 'reset_en' : 'reset_ar']"
                  class="icoNissan reset uppercase"
                  v-on:click="featuresOpen('reset')"
                >
                  {{ $t("RESET_CONFIGURATION") }}
                </button>
              </div>
            </div>
          </div>
          <div v-show="currentTab === 1">
            <div class="techSpec">
              <section>
                <h3 class="uppercase font-semibold text-base">
                  {{ $t("CONSUMPTION") }}
                </h3>
              </section>
              <section>
                <h3 class="uppercase font-semibold text-base">
                  {{ $t("ENGINE_CATEGORY") }}
                </h3>
                <div class="mt-8 inner">
                  <ul
                    class="w-full"
                    v-if="getGradObj && getGradObj.technical_specs"
                  >
                    <li
                      class="flex justify-between font-medium text-sm"
                      v-if="getGradObj.technical_specs.Engine_Type"
                    >
                      <label>{{ $t("ENGINE") }}</label
                      ><span v-if="language != 'ar'">{{
                        getGradObj.technical_specs.Engine_Type.values[0].value
                      }}</span>
                      <span v-else>{{
                        getGradObj.technical_specs.Engine_Type.values[0]
                          .value_ar
                      }}</span>
                    </li>

                    <li
                      class="flex justify-between font-medium text-sm"
                      v-if="getGradObj.technical_specs.Max_Engine_Power_hp__rpm"
                    >
                      <label>{{ $t("MAX") }}</label>
                      <span v-if="language != 'ar'">{{
                        getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                          .values[0].value
                      }}</span>
                      <span v-else>{{
                        getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                          .values[0].value_ar
                      }}</span>
                    </li>

                    <li
                      class="flex justify-between font-medium text-sm"
                      v-if="getGradObj.technical_specs.Capacity_l"
                    >
                      <label>{{ $t("CAPACITY") }}</label
                      ><span v-if="language != 'ar'">{{
                        getGradObj.technical_specs.Capacity_l.values[0].value
                      }}</span>
                      <span v-else>{{
                        getGradObj.technical_specs.Capacity_l.values[0].value_ar
                      }}</span>
                    </li>
                    <li
                      class="flex justify-between font-medium text-sm"
                      v-if="getGradObj.technical_specs.Number_of_Gears"
                    >
                      <label>{{ $t("GEARS") }}</label
                      ><span v-if="language != 'ar'">{{
                        getGradObj.technical_specs.Number_of_Gears.values[0]
                          .value
                      }}</span>
                      <span v-else>{{
                        getGradObj.technical_specs.Number_of_Gears.values[0]
                          .value_ar
                      }}</span>
                    </li>
                    <li
                      class="flex justify-between font-medium text-sm"
                      v-if="getGradObj.technical_specs.Fuel_Type"
                    >
                      <label>{{ $t("FUEL") }}</label
                      ><span v-if="language != 'ar'">{{
                        getGradObj.technical_specs.Fuel_Type.values[0].value
                      }}</span>
                      <span v-else>{{
                        getGradObj.technical_specs.Fuel_Type.values[0].value_ar
                      }}</span>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
          <div v-show="currentTab === 2">
            <div class="equipment mt-8" v-if="getGradObj.equipments">
              <section
                class="w-full mt-3 mb-6"
                v-for="item in getGradObj.equipments"
                :key="item.id"
              >
                <h3
                  class="uppercase font-semibold text-base"
                  v-if="item.name && language != 'ar'"
                >
                  {{ item.name }}
                </h3>
                <h3 class="uppercase font-semibold text-base" v-else>
                  {{ item.name_ar }}
                </h3>
                <ul class="mt-5" v-if="item.values">
                  <li
                    class="w-full text-sm font-medium icoNissan"
                    v-for="itemVal in item.values"
                    :key="itemVal.id"
                  >
                    <span v-if="language != 'ar'"> {{ itemVal.name }}</span>
                    <span v-else> {{ itemVal.name_ar }}</span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="onlineForm">
        <div class="uppercase font-semibold head">BOOK A TEST DRIVE</div>
        <div class="uppercase font-semibold text-base mt-1">
          PLEASE FILL IN YOUR DETAILS
        </div>
        <div class="formInner mt-10">
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">Select from a list</label>
            <div class="customSelector">
              <select class="formControl">
                <option selected="selected" disabled="disabled" value=""
                  >Select from a list</option
                >
                <option>ALTIMA</option>
                <option>PATROL</option>
              </select>
            </div>
          </div>
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">SHOWROOM LOCATION</label>
            <div class="customSelector">
              <select class="formControl">
                <option selected="selected" disabled="disabled" value=""
                  >Select from a list</option
                >
                <option>DUBAI - DEIRA</option>
                <option>DUBAI - SHEIKH ZAYED ROAD</option>
                <option>SHARJAH - SHEIKH MOHAMMED BIN ZAYED ROAD</option>
                <option>AJMAN</option>
                <option>UMM AL QUWAIN</option>
              </select>
            </div>
          </div>
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">FIRST NAME</label>
            <input type="text" class="formControl" />
          </div>
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">SURNAME</label>
            <input type="text" class="formControl" />
          </div>
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">E-MAIL</label>
            <input type="email" class="formControl" />
          </div>
          <div class="w-full relative mb-8 dropDown">
            <label class="uppercase forLabel">MOBILE NO.</label>
            <input type="tel" placeholder="971501234567" class="formControl" />
          </div>
          <div class="btnWrap">
            <button class="bg-herored text-white icoNissan submitBtn">
              SEND
            </button>
            
          </div>
          <p class="flex font-medium text-sm items-center">
            <span class="text-2xl mr-2">*</span> Required field
          </p>
        </div>
      </div> -->
      <div class="w-full outerBtn-SM md-custom:block md-custom-min:hidden">
        <a
          target="_parent"
          v-if="
            getallCars.model &&
              getallCars.model.model.toLowerCase() != 'sunny' &&
              getallCars.model.model.toLowerCase() != 'x-terra'
          "
          :href="
            language == 'en'
              ? parent_url +
                'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
              : parent_url +
                'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
          "
          class="w-full text-left text-sm font-normal bg-herored text-white relative uppercase btn"
        >
          <span> {{ $t("TEST_DRIVE") }} </span>
        </a>
        <a
          target="_parent"
          v-else-if="getallCars.model"
          :href="
            language == 'en'
              ? parent_url +
                'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
              : parent_url +
                'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
          "
          class="w-full text-left text-sm font-normal bg-herored text-white relative uppercase btn"
        >
          <span> {{ $t("TEST_DRIVE") }} </span>
        </a>

        <a
          target="_parent"
          v-if="
            getallCars.model &&
              getallCars.model.model.toLowerCase() != 'sunny' &&
              getallCars.model.model.toLowerCase() != 'x-terra'
          "
          :href="
            language == 'en'
              ? parent_url +
                get_quote_url +
                '/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
              : parent_url +
                get_quote_url +
                '/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
          "
          class="w-full text-left text-sm font-normal bg-herored text-white relative uppercase mt-3 btn"
        >
          <span> {{ $t("ONLINE_QUOTE") }}</span>
        </a>
        <a
          target="_parent"
          v-else-if="getallCars.model"
          :href="
            language == 'en'
              ? parent_url +
                get_quote_url +
                '/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
              : parent_url +
                get_quote_url +
                '/?cmpT=Configurator.iFrame&vehicle=' +
                codes[getallCars.model.model.toLowerCase()]
          "
          class="w-full text-left text-sm font-normal bg-herored text-white relative uppercase mt-3 btn"
        >
          <span> {{ $t("ONLINE_QUOTE") }}</span>
        </a>

        <a
          target="_parent"
          v-if="getallCars.model"
          :href="codes[getallCars.model.model.toLowerCase() + '_pdf']"
          class="uppercase text-sm font-medium flex items-center mt-5 extLink"
          ><span> {{ $t("DOWNLOAD_BROCHURE") }}</span></a
        >
      </div>

      <div class="utilityBar md-custom:hidden">
        <div class="flex justify-center items-center h-full utilityBtns">
          <button
            class="icoNissan text-white uppercase font-semibold text-sm footBtn md-custom-min:block md-custom:hidden"
            @click="print"
          >
            {{ $t("PRINT") }}
          </button>
          <button
            v-on:click="featuresOpen('save')"
            class="icoNissan text-white uppercase font-semibold text-sm footBtn"
          >
            {{ $t("SAVE") }}
          </button>
          <button
            v-on:click="featuresOpen('share')"
            class="icoNissan text-white uppercase font-semibold text-sm footBtn"
          >
            {{ $t("SHARE") }}
          </button>
        </div>
      </div>
    </section>
    <section class="flex  md-custom-min:hidden md-custom:flex">
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <NamePrice />
        </div>
      </div>
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <ImageThumbnail v-on:popupClicked="popupOpen()" />
        </div>
      </div>

      <VehiclePopup v-if="vehiclePopup" v-on:popupClosed="popupClose()" />
    </section>

    <section
      class="sidePanel md-custom:hidden"
      :style="language != 'ar' ? 'right:15px' : 'left:-10px'"
    >
      <div class="panelContent">
        <h2
          id="model"
          class="text-2xl font-normal uppercase"
          v-if="getallCars.model && language != 'ar'"
        >
          {{ getallCars.model.model }}
        </h2>
        <h2
          id="model"
          class="text-2xl font-normal uppercase"
          v-else-if="getallCars.model && language == 'ar'"
        >
          {{ getallCars.model.model_ar }}
        </h2>
        <span
          class="text-sm font-semibold uppercase"
          v-if="getGradObj && getGradObj.technical_specs"
          >{{ getGradObj.technical_specs.Engine_Type.values[0].value }}
          {{ getGradObj.Version_Label }}
        </span>
        <div class="mt-6">
          <span class="text-xs font-normal">{{ $t("TOTAL_PRICE") }}</span>
          <div class="text-2xl font-light mt-1 flex">
            <span
              :class="[language == 'en' ? 'order-1  px-1' : 'order-2 px-1']"
            >
              {{ $t("CURRENCY") }}</span
            >
            <span :class="[language == 'en' ? 'order-2' : 'order-1']">
              {{ Number(getGradObj.price).toLocaleString() }}</span
            >
          </div>
        </div>
        <div class="flex flex-col w-full mt-10">
          <a
            target="_parent"
            v-if="
              getallCars.model &&
                getallCars.model.model.toLowerCase() != 'sunny' &&
                getallCars.model.model.toLowerCase() != 'x-terra'
            "
            :href="
              language == 'en'
                ? parent_url +
                  'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
                : parent_url +
                  'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
            "
            :class="[language != 'ar' ? 'btn btn_en' : 'btn btn_ar']"
            class="w-full redirect-link text-left text-sm font-normal bg-herored text-white relative uppercase "
          >
            <span> {{ $t("TEST_DRIVE") }}</span>
          </a>
          <a
            target="_parent"
            v-else-if="getallCars.model"
            :href="
              language == 'en'
                ? parent_url +
                  'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
                : parent_url +
                  'book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
            "
            :class="[language != 'ar' ? 'btn btn_en' : 'btn btn_ar']"
            class="w-full redirect-link text-left text-sm font-normal bg-herored text-white relative uppercase"
          >
            <span> {{ $t("TEST_DRIVE") }}</span>
          </a>

          <a
            target="_parent"
            v-if="
              getallCars.model &&
                getallCars.model.model.toLowerCase() != 'sunny' &&
                getallCars.model.model.toLowerCase() != 'x-terra'
            "
            :href="
              language == 'en'
                ? parent_url +
                  get_quote_url +
                  '/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
                : parent_url +
                  get_quote_url +
                  '/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
            "
            :class="[language != 'ar' ? 'btn btn_en' : 'btn btn_ar']"
            class="w-full text-left redirect-link  text-sm font-normal bg-herored text-white relative uppercase"
          >
            <span> {{ $t("ONLINE_QUOTE") }}</span>
          </a>
          <a
            target="_parent"
            v-else-if="getallCars.model"
            :href="
              language == 'en'
                ? parent_url +
                  get_quote_url +
                  '/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
                : parent_url +
                  get_quote_url +
                  '/?cmpT=Configurator.iFrame&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
            "
            :class="[language != 'ar' ? 'btn btn_en' : 'btn btn_ar']"
            class="w-full text-left redirect-link  text-sm font-normal bg-herored text-white relative uppercase"
          >
            <span> {{ $t("ONLINE_QUOTE") }}</span>
          </a>
        </div>

        <a
          target="_parent"
          v-if="getallCars.model"
          :href="codes[getallCars.model.model.toLowerCase() + '_pdf']"
          class="uppercase text-sm font-medium flex items-center mt-10 extLink"
          :class="[language != 'ar' ? 'extLink_en' : 'extLink_ar']"
          ><span>{{ $t("DOWNLOAD_BROCHURE") }}</span></a
        >
      </div>
    </section>
    <Features
      v-if="featuresComponent"
      :type="modalType"
      v-on:closeModal="featuresOpen"
      :featuresIsOpen="featuresComponent"
      v-bind:car="getGradObj"
      :extColor="selectedExtColorSpot"
    />
    <div id="printMe">
      <div>
        <div style="display:flex;justify-content:space-between;width:30%">
          <div>
            <img
              src="https://di-uploads-pod2.dealerinspire.com/advantagenissan/uploads/2018/04/logo.png"
            />
          </div>

          <div>
            Print
            {{
              new Date()
                .toJSON()
                .slice(0, 10)
                .replace(/-/g, "/")
            }}
          </div>
        </div>
        <h2
          class="uppercase"
          v-if="getallCars.model && language != 'ar'"
          style="font-size: 39px;margin-bottom:0"
        >
          {{ getallCars.model.model }}
        </h2>
        <h2
          class="uppercase"
          v-else-if="getallCars.model && language == 'ar'"
          style="font-size: 39px;margin-bottom:0"
        >
          {{ getallCars.model.model_ar }}
        </h2>
        <h4
          class="text-sm font-semibold uppercase"
          v-if="getGradObj && getGradObj.technical_specs"
          style="font-size: 22px;margin-top:10px"
        >
          {{ getGradObj.technical_specs.Engine_Type.values[0].value }}
          {{ getGradObj.Version_Label }}
        </h4>
        <div>
          <span style="display:block;font-size: 1.3rem;margin-top:18px;">{{
            $t("TOTAL_PRICE")
          }}</span>
          <div style="font-size: 2.5rem;font-weight:500;">
            {{ $t("CURRENCY") }}{{ Number(getGradObj.price).toLocaleString() }}
          </div>
        </div>
        <div
          style="width:40%;display:flex;justify-content:space-between;margin-top:20px;"
        >
          <div style="border:1px solid #e3e2e2;margin-right:20px">
            <img :src="selectedExtColor + '/01.jpg'" style="height:300px" />
          </div>
          <div>
            <img
              :src="selectedIntColor.image_url"
              style="object-fit: cover;
        object-position: -90% -90%;
        zoom:5;
        width: 200px;
        height: 100px;"
            />
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1>{{ $t("CONFIGURATOR") }}</h1>
            <table
              style="border-collapse: collapse;width: 100%;padding-bottom:1.5em;"
            >
              <tr
                style="border-bottom:1px solid #d2d2d2; padding-top:7px;"
                v-if="getGradObj"
              >
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b> {{ $t("VERSIONS") }}</b>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ getGradObj.Version_Label }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("CURRENCY") }}
                  {{ Number(getGradObj.price).toLocaleString() }}
                </td>
              </tr>
              <tr style="border-bottom:1px solid #d2d2d2; padding-top:7px;">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>{{ $t("TABS.COLOURS") }}</b>
                </td>
                <td
                  style="font-size: 22px; padding: 1em 0px;"
                  v-if="selectedExtColorName.name"
                >
                  <span v-if="language != 'ar'">
                    {{ selectedExtColorName.name }}
                  </span>
                  <span v-else> {{ selectedExtColorName.name_ar }} </span>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
              <tr style="border-bottom:1px solid #d2d2d2;padding-top:7px;">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>
                    <b>{{ $t("TABS.UPHOLSTERY") }}</b></b
                  >
                </td>
                <td
                  style="font-size: 22px; padding: 1em 0px;"
                  v-if="selectedIntColor.name"
                >
                  <span v-if="language != 'ar'">
                    {{ selectedIntColor.name }}</span
                  >
                  <span v-else> {{ selectedIntColor.name_ar }}</span>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
              <tr v-if="getGradObj.wheel">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>{{ $t("TABS.WHEELS") }} </b>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  <span v-if="language != 'ar'">
                    {{ getGradObj.wheel[0].name }}</span
                  >
                  <span v-else> {{ getGradObj.wheel[0].name_ar }}</span>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
            </table>
          </div>
          <div
            style="width:100%;display:flex;justify-content: flex-end;flex-direction: column; "
          >
            <span
              style="display:block;font-size: 1.3rem;text-align:right;margin-top:18px;"
              >{{ $t("TOTAL_PRICE") }}</span
            >
            <div style="text-align:right; font-size: 2.5rem;font-weight: 500;">
              {{ $t("CURRENCY")
              }}{{ Number(getGradObj.price).toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1 style="display:block; margin-top:1em">
              {{ $t("CONFIGURATOR") }}
            </h1>
            <h3 style="font-size: 1.2rem;">{{ $t("CONSUMPTION") }}</h3>
            <h3 style="font-size: 1.2rem;">{{ $t("ENGINE_CATEGORY") }}</h3>
            <table
              v-if="getGradObj && getGradObj.technical_specs"
              style="border-collapse: collapse;width: 100%;padding-bottom:1.5em;"
            >
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Engine_Type"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("ENGINE") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">{{
                    getGradObj.technical_specs.Engine_Type.values[0].value
                  }}</span>
                  <span v-else>{{
                    getGradObj.technical_specs.Engine_Type.values[0].value_ar
                  }}</span>
                </td>
              </tr>

              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Max_Engine_Power_hp__rpm"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("MAX") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                        .values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                        .values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>

              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Capacity_l"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("CAPACITY") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Capacity_l.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Capacity_l.values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Number_of_Gears"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("GEARS") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Number_of_Gears.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Number_of_Gears.values[0]
                        .value_ar
                    }}</span
                  >
                </td>
              </tr>
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Fuel_Type"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("FUEL") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Fuel_Type.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Fuel_Type.values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1>{{ $t("EQUIPMENT") }}</h1>
            <div class="equipment mt-8" v-if="getGradObj.equipments">
              <section
                class="w-full mt-3 mb-6"
                v-for="item in getGradObj.equipments"
                :key="item.id"
              >
                <h2
                  class="uppercase font-semibold text-base"
                  v-if="language != 'ar'"
                >
                  {{ item.name }}
                </h2>
                <h2 class="uppercase font-semibold text-base" v-else>
                  {{ item.name_ar }}
                </h2>
                <ul
                  class="mt-5"
                  v-if="item.values"
                  style="display:block;list-style: none; padding-bottom:1.3em;"
                >
                  <li
                    style="font-size: 24.5px;padding:0.65em 0;border-bottom:1px solid #d2d2d2;"
                    class="w-full text-sm font-medium icoNissan"
                    v-for="itemVal in item.values"
                    :key="itemVal.id"
                  >
                    <span style="font-size:22px;margin-right:7px"
                      >&#10004;</span
                    >
                    <span v-if="language != 'ar'"> {{ itemVal.name }}</span>
                    <span v-else> {{ itemVal.name_ar }}</span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PreviewBox from "../colors/360";
import Interior from "../colors/interior";
import NamePrice from "../../../components/smallScreen360-carDetails/namePrice";
import ImageThumbnail from "../../../components/smallScreen360-carDetails/imageThumbnail";
import VehiclePopup from "../../../components/smallScreen360-carDetails/vechiclePopup";
import Features from "../../../components/sidePanel/panelView";

export default {
  props: [],
  data() {
    return {
      output: null,
      featuresComponent: false,
      modalType: "showFeatures",
      isActive: false,
      showPrint: false,
      interior: false,
      exterior: true,
      vehiclePopup: false,
      isTriggered: false,
      isExpand: false,

      currentTab: 0,
      tabs: "",
      codes: {
        altima: "L34",
        altima_pdf:
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-ALTIMA-2019-CATALOGUE.pdf",
        kicks: "P15",
        kicks_pdf:
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-KICKS-CATALOGUE.pdf",
        maxima: "A36",
        maxima_pdf:
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-MAXIMA-2020-CATALOGUE.pdf",
        patrol: "Y62",
        patrol_pdf:
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-PATROL-2020-CATALOGUE.pdf",
        sunny: "N18",
        sunny_pdf:
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-SUNNY-CATALOGUE.pdf",
        "x-trail": "T32",
        "x-trail_pdf":
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-X-TRAIL-CATALOGUE.pdf",
        "x-terra": "P60A",
        "x-terra_pdf":
          "https://www-europe.nissan-cdn.net/content/dam/Nissan/nissan_middle_east/brochures/LIB/NISSAN-X-TERRA-CATALOGUE.pdf",
      },
    };
  },
  computed: {
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
    parent_url() {
      const site = document.location.href.split("?")[1];
      const goblal_site = site.split("#")[0];

      if (goblal_site == "site=ma") {
        const lang =
          this.$store.state.Pages.language.trim() == "en" ? "en." : "www.";
        return "https://" + lang + "nissan-manahil.com/";
      } else {
        const lang =
          this.$store.state.Pages.language.trim() == "en" ? "en." : "www.";
        return "https://" + lang + "petromin-nissan.com/";
      }
    },
    get_quote_url() {
      const site = document.location.href.split("?")[1];
      const goblal_site = site.split("#")[0];

      if (goblal_site == "site=ma") {
        return "request-a-quote";
      } else {
        return "get-a-quote";
      }
    },

    ...mapGetters(["getGradObj"]),
    ...mapGetters(["getGrade"]),
    ...mapGetters(["getallCars"]),

    IsLoading() {
      return this.$store.getters.isLoading;
    },
    language() {
      return this.$store.state.Pages.language;
    },

    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedExtColorSpot() {
      var OBJ = this.$store.getters.selectedColors;
      const data = {
        spot: OBJ.spot,
        name: OBJ.name,
      };
      return data;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
  },
  methods: {
    print() {
      this.tagging("print", "print", "summary");
      let contents = document.getElementById("printMe").innerHTML;
      let model = document.getElementById("model").innerHTML;
      let frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(
        '<html lang="en"><head><title>2020 Nissan ' +
          model +
          "  - Configure Colors, Options & Accessories In 360</title>"
      );

      frameDoc.document.write(
        '<link href="../../assets/css/app.css" rel="stylesheet" type="text/css" />'
      );
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      setTimeout(function() {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
      // this.showPrint=true;
      // this.$htmlToPaper('printMe');
    },
    featuresOpen(type) {
      this.modalType = type;
      this.featuresComponent = !this.featuresComponent;
      if (this.featuresComponent) {
        this.tagging(type, type, "summary");
      }
    },
    ...mapActions(["changePage"]),
    tag() {
      console.log("object");
    },

    ExView() {
      if (this.interior == true) {
        this.interior = false;
        this.isActive = true;
        this.exterior = true;
      } else {
        this.interior = true;
        this.exterior = false;
        this.isActive = false;
      }
      this.tagging(
        "ext_int_toggle",
        this.interior == true ? "interior view" : "exterior view",
        "summary"
      );
    },
    popupOpen() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    popupClose() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    expandDiv() {
      if (this.isExpand) {
        this.isExpand = false;
        this.isTriggered = !this.isTriggered;
      } else if (this.interior) {
        this.interior = false;
        this.exterior = true;
      } else {
        this.isExpand = true;
        this.isTriggered = true;
      }
    },
    tagging(type, buttonText, location) {
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: type,
        buttonText: buttonText,
        location: location,
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },

  beforeCreate: function() {
    //document.body.className = 'summaryPage';
  },
  created() {
    if (this.$store.state.Pages.language == "en") {
      this.tabs = ["Configurator", "Technical Specifications", "Equipment"];
    } else {
      this.tabs = ["التجهيزات", "المواصفات الفنية", "تخصيص"];
    }

    //var

    setTimeout(() => {
      if (this.$store.state.Pages.currentPage == 4) {
        this.tagging("load", "direct", "direct");
      }
    }, 3000);
  },
  mounted() {
    this.exterior = true;
  },

  components: {
    Features,
    PreviewBox,
    Interior,
    NamePrice,
    ImageThumbnail,
    VehiclePopup,
  },
};
</script>

.<style scoped>
redirect-link {
  cursor: pointer;
}
table {
  width: 100%;
}
.printTable .head {
  font-size: 20px;
}
table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
tr td {
  display: flex;
}
#printMe {
  width: 100%;
  font-size: 50px;
  font-family: url("http://localhost:8080/src/assets/fonts/NissanBrand-Regular.ttf");
}
.price {
  font-family: url("http://localhost:8080/src/assets/fonts/NissanBrand-Regular.ttf");
}
.previewWrap {
  width: calc(100% - 298px);
}
.summaryPreview {
  transition: width 0.3s ease;
}
.toggleUp.active,
.toggleDown.active {
  color: #fff;
  background-color: #c3002f;
}
.summaryWrap {
  position: relative;
  /* overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; */
}
.forInterior {
  /* min-height: 490px; */
  transition: 0.3s;
  overflow: hidden;
}
.forExterior {
  transition: 0.3s;
  overflow: hidden;
  min-height: 240px;
}
.sidePanel {
  /* position: sticky; */
  position: fixed;
  padding-top: 21px;
  height: 100vh;
  top: 105px;

  flex-flow: column nowrap;
  width: 278px;
  margin-left: 1.07143em;
}
.toggleContainer {
  position: absolute;
  height: 90px;
  padding: 0.35714em 5px;
  margin: 0.71429em 10px;
  border-radius: 10px;
  z-index: 2;
  cursor: pointer;
  border: 1px solid #eee;
  background-color: #f6f6f6;
  top: 0;
}
#printMe {
  display: none;
}
.innerr {
  position: relative;
  height: 100%;
  z-index: 2;
}
.toggleUp,
.toggleDown {
  padding: 0 8px;
  left: 0;
  min-width: 87px;
  width: auto;
  height: 50%;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.9em;
}
.toggleUp {
  top: 0;
}
.toggleDown {
  bottom: 0;
}
.toggleControl {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  border: 0;
  border-radius: 6px;
  z-index: -1;
  transition: 0.3s;
  color: #fff;
  background-color: #c3002f;
}
.btn {
  padding: 1em 20px;
  margin-bottom: 0.75em;
}
.btn span {
  color: #fff;
}

.btn_ar {
  display: flex;
}
.btn_ar::after {
  left: 20px;
  right: auto;
  content: "\f104";
}
.btn_en::after {
  right: 20px;
  left: auto;
  content: "\f105";
}
.btn::after {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  font-size: 16px;
}

.extLink::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  font-size: 17px;

  color: #c3002f;
}
.extLink_en::after {
  margin-left: 9px;
  content: "\f105";
}
.extLink_ar::after {
  margin-right: 9px;
  content: "\f104";
}
.extLink span:hover,
.extLink span:focus {
  text-decoration: underline;
}
ul.tabPills li {
  background-color: #f6f6f6;
  border-right: 1px solid #fff;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 1.19em 13px;
  display: flex;
  align-items: center;
  font-size: 0.95em;
  color: #343434;
}
ul.tabPills li.active {
  background-color: #fff;
  box-shadow: inset 0 5px 0 #c3002f;
  color: #000;
  font-weight: 600;
}
.configurator ul li {
  border-bottom: 1px solid #e3e2e2;
  padding: 0 15px;
}
.configurator ul li .itemWrap {
  width: 100%;
  padding: 20px 0;
}
.configurator ul li .item .left {
  flex: 0 0 25%;
}
.configurator ul li .item .right {
  width: 100%;
  flex: 1 1 auto;
  justify-content: space-between;
}
.left .action .edit::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f304";
  font-size: 15px;
  color: #c3002f;
}
.right .action {
  flex-basis: 80%;
}
.icnBtn::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 17px;
}
.info::after {
  content: "\f05a";
}
.view::after {
  content: "\f06e";
}
.totalPrice {
  background-color: #f9f9f9;
  border-bottom: 1px solid #d9d9d9;
  min-height: 6.42857em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  padding: 0.35714em 15px;
  flex-direction: column;
  width: 100%;
}
.icoNissan::before,
.icoNissan::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.reset::before {
  content: "\f0e2";

  color: #c3002f;
}
.reset_en::before {
  margin-right: 6px;
}
.reset_ar::before {
  margin-left: 6px;
}
.techSpec section {
  margin: 2.5em 0;
  margin-top: 2em;
}
.techSpec,
.equipment {
  padding: 0 15px;
}
.techSpec li {
  border-bottom: 1px solid #e3e2e2;
  padding: 1.42857em 0;
}
.equipment li {
  color: #343434;
  border-bottom: 1px solid #e3e2e2;
  padding: 1.42857em 10px;
  position: relative;
  padding-left: 25px;
  line-height: 1.3;
  display: flex;
  align-items: center;
}
.equipment li::before {
  content: "\f00c";
  position: absolute;
  left: 0px;
}
.onlineForm {
  padding: 4.85714em 20%;
  background-color: #f9f9f9;
}
.onlineForm .head {
  font-size: 1.75em;
}

.customSelector {
  display: flex;
  zoom: 1;
  position: relative;
  width: 100%;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}
.customSelector > select {
  left: 0;
  right: 0;
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 0.1666666667em;
  box-shadow: 0 0 0 0.1428571429em #eee,
    inset 0 0.1111111111em 0.2222222222em -0.0555555556em rgba(0, 0, 0, 0.15);
  line-height: normal;
  margin-bottom: -0.0555555556em;
  padding: 0.6em 9px 0.6em;
  background-color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.customSelector:before {
  content: "";
  right: 47px;
  height: 75%;
  width: 1px;
  display: block;
  position: absolute;
  top: 6px;
  border-left: 1px solid #d2d2d2;
}
.customSelector:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1.7em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 29px;
}
.customSelector option {
  font-weight: 600;
  font-size: 1.2em;
}
.forLabel {
  font-size: 0.7571428571em;
  margin-bottom: 0.7666666667em;
  font-weight: 600;
  display: block;
}
.forLabel::after {
  color: #c3002f;
  content: "*";
  font-size: 16px;
}
.formControl {
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 0.1666666667em;
  box-shadow: 0 0 0 0.1428571429em #eee,
    inset 0 0.1111111111em 0.2222222222em -0.0555555556em rgba(0, 0, 0, 0.15);
  line-height: normal;
  margin-bottom: -0.0555555556em;
  padding: 0.6em 9px 0.6em;
  background-color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.formControl:focus {
  border: 1px solid #8a8a8a;
  outline: 0;
  box-shadow: 0 0 0 0.1428571429em #c1c1c1;
}
input[type="tel"].formControl::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.61);
}
.submitBtn {
  padding: 0.8571428571em 4.64286em 0.8571428571em 20px;
  margin-top: 1px;
  margin-bottom: 0.7857142857em;
  position: relative;
}
.submitBtn::after {
  content: "\f105";
  font-size: 16px;
  right: 20px;
  top: 50%;
  overflow: hidden;
  margin-top: -0.5em;
  position: absolute;
}
.utilityBar {
  position: fixed;
  bottom: 0px;
  height: 3.14286em;
  z-index: 2;
  background-color: #343434;
  /* width: auto; */
  width: calc(100% - 313px);
  left: 0;
  right: 0;
  top: auto;
}
.utilityBtns .footBtn {
  margin-right: 3em;
}
.utilityBtns .footBtn::before {
  margin-right: 15px;
  font-size: 16px;
}
.utilityBtns .footBtn:nth-child(1)::before {
  content: "\f02f";
}
.utilityBtns .footBtn:nth-child(2)::before {
  content: "\f0c7";
}
.utilityBtns .footBtn:nth-child(3)::before {
  content: "\f1e0";
}
.trayBtm li a {
  font-size: 10.1px;
}
.expandBtn {
  top: 0;
  position: relative;
  width: 46px;
  height: 46px;
  background: hsla(0, 0%, 100%, 0.6);
}
.expandBtn::after {
  font-size: 25px;
  color: #c3002f;
}
.expandBtn::after {
  content: "\f424";
}
.expandBtn.triggered::after {
  content: "\f422";
}
.mobEdit {
  width: 51px;
  justify-content: center;

  background-color: #f9f9f9;
}
.mobEdit button {
  width: 100%;
}
.mobEdit i {
  color: #c3002f;
  font-size: 15px;
}
.outerBtn-SM {
  background: #fff;
  padding: 0em 20%;
  margin-top: 1.5em;
  padding-bottom: 1.5em;
}
.outerBtn-SM .btn {
  display: block;
}
@media print {
  #printMe {
    display: initial;
  }
}
@media (max-width: 960px) {
  .submitBtn {
    width: 100%;
    text-align: left;
  }
  ul.tabPills li {
    justify-content: center;
  }
  .previewWrap {
    width: 100%;
  }
  button.reset {
    padding-right: 15px;
  }
  button.reset_ar {
    padding-left: 15px;
  }
  .labelIn {
    margin-right: 1em;
  }
  .configurator ul li {
    padding-right: 0;
  }
  .mobEdit {
    display: flex;
  }
  .itemWrap .item {
    flex-direction: column;
  }
  .action {
    margin-bottom: 1.2em;
  }
  .action .icnBtn {
    margin-right: 1.2em;
  }
  .action span {
    margin: 0;
  }
  .action .edit {
    display: none;
  }
}

@media (max-width: 668px) {
  ul.tabPills li {
    font-size: 13px;
    line-height: 1.3;
  }
  .labelIn {
    margin-right: 1em;
  }
  .right .action {
    flex-basis: auto;
  }
  .onlineForm {
    padding: 1.85714em 2.21%;
    background-color: #f9f9f9;
  }
  .outerBtn-SM {
    padding: 0em 2.21% 1.5em;
  }
}

@media (min-width: 960px) {
  .mobEdit {
    display: none;
  }
}
</style>
