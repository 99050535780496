<template>
  <div class="priceName">
    <div class="pt-3 px-3">
      <div class="w-full mb-3 heroHead">
        <h2 class="uppercase" v-if="getallCars.model">
          <span v-if="language != 'ar'"> {{ getallCars.model.model }} </span>
          <span v-else>{{ getallCars.model.model_ar }}</span>
        </h2>
        <h4 v-if="getGradObj && getGradObj.technical_specs && language != 'ar'">
          {{ getGradObj.technical_specs.Engine_Type.values[0].value }}
          {{ getGradObj.Version_Label }}
        </h4>
        <h4
          v-else-if="
            getGradObj && getGradObj.technical_specs && language == 'ar'
          "
        >
          {{ getGradObj.technical_specs.Engine_Type.values[0].value_ar }}
          {{ getGradObj.Version_Label }}
        </h4>
      </div>
      <div class="w-full mb-3">
        <span class="priceLabel">{{ $t("TOTAL_PRICE") }}</span>
        <div class="priceInn flex" v-if="getGradObj">
          <span :class="[language == 'en' ? 'order-1' : 'order-2']">{{
            $t("CURRENCY")
          }}</span>
          <span :class="[language == 'en' ? 'order-2' : 'order-1']"
            >{{ Number(getGradObj.price).toLocaleString() }}
          </span>
          <span :class="[language == 'en' ? 'order-2' : 'order-3']">*</span>
        </div>
      </div>

      <a
        v-if="
          getallCars.model &&
            getallCars.model.model.toLowerCase() != 'sunny' &&
            getallCars.model.model.toLowerCase() != 'x-terra'
        "
        :href="
          language == 'en'
            ? 'https://en.petromin-nissan.com/book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
              codes[getallCars.model.model.toLowerCase()]
            : 'https://petromin-nissan.com/book-a-test-drive/?cmpT=Configurator.iFrame&vehicle=' +
              codes[getallCars.model.model.toLowerCase()]
        "
        class="flex items-center text-xs outerLink uppercase"
      >
        {{ $t("TEST_DRIVE") }}
        <i
          class="fas  text-base text-herored"
          :class="[
            language != 'ar'
              ? 'fas fa-angle-right ml-2 '
              : 'fas fa-angle-left mr-2',
          ]"
        ></i
      ></a>

      <a
        v-else-if="getallCars.model"
        :href="
          language == 'en'
            ? 'https://en.nissan-manahil.com/book-a-test-drive/?cmpT=CompareModels.iFrame&vehicle=' +
              codes[getallCars.model.model.toLowerCase()]
            : 'https://nissan-manahil.com/book-a-test-drive/?cmpT=CompareModels.iFrame&vehicle=' +
              codes[getallCars.model.model.toLowerCase()]
        "
        class="flex items-center text-xs outerLink uppercase"
      >
        {{ $t("TEST_DRIVE") }}
        <i
          class="  text-base text-herored "
          :class="[
            language != 'ar'
              ? 'fas fa-angle-right ml-2 '
              : 'fas fa-angle-left mr-2',
          ]"
        ></i
      ></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      codes: {
        altima: "L34",
        kicks: "P15",
        maxima: "A36",
        patrol: "Y62",
        sunny: "N18",
        "x-trail": "T32",
        "x-terra": "P60A",
      },
    };
  },
  computed: {
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["getallCars"]),

    language() {
      return this.$store.state.Pages.language;
    },
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
  },
};
</script>

<style scoped>
.heroHead h2 {
  font-size: 0.88em;
  font-weight: 600;
}
.heroHead h4 {
  font-weight: 300;
  font-size: 0.75em;
  margin-top: 2px;
  margin-bottom: 0.35714em;
}
.priceLabel {
  font-size: 11px;
}
.priceInn {
  font-size: 1.1em;
  line-height: 1;
  font-weight: 300;
  color: #000;
  margin-top: 3px;
}
@media (max-width: 668px) {
  .outerLink i {
    margin-left: 0.3rem;
  }
}
</style>
