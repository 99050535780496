// var url =
//   window.location != window.parent.location
//     ? document.referrer
//     : document.location.href;
// // var host = window.location.hostname;
// console.log("url", url);
// var referer = window.parent.location.href;
// referer.replace(".html", "");
window.dataLayer = window.dataLayer || [];

// var previous_page = referer.split("/");
// function getPreviousPage() {
//   var name = "";

//   if (previous_page.length == 4) {
//     return "Homepage";
//   } else if (previous_page.length == 4 && url.length == 2) {
//     return "Homepage";
//   } else if (previous_page.length <= 1 && url.length == 2) {
//     return "Homepage";
//   } else if (previous_page.length == 1 && url.length > 2) {
//     var filtered = url.filter((item) => item);
//     for (let index = 0; index < filtered.length; index++) {
//       if (index + 1 == filtered.length) {
//         switch (filtered[index]) {
//           case "shedrives":
//             return "she-drives";

//           case "fleet":
//             return "nissan-fleet";

//           default:
//             previous_page[index];
//             break;
//         }
//         name += filtered[index];

//         if (filtered[index] == "vehiles" && filtered.length == 2) {
//           name += "|overview";
//         }
//       } else {
//         name += filtered[index] + "|";
//       }
//     }
//     return name;
//   } else {
//     var myarray = previous_page.filter((item) => item);
//     for (let index = 2; index < myarray.length; index++) {
//       if (myarray[index] != "") {
//         console.log(myarray[index]);
//         if (index + 1 == myarray.length) {
//           switch (myarray[index]) {
//             case "shedrives":
//               return "she-drives";

//             case "fleet":
//               return "nissan-fleet";

//             default:
//               myarray[index];
//               break;
//           }

//           name += myarray[index];

//           //vevhiles
//           if (myarray[2] == "vehicles" && myarray.length == 4) {
//             name += "|overview";
//           }
//         } else {
//           if (myarray[index] != "") {
//             name += myarray[index] + "|";
//           }
//         }
//       }
//     }
//     return name;
//   }
// }

export default {
  tag(data) {
    console.log("currentpage", data);
    var s;
    var pages = ["Versions", "Colours", "Upholstery", "Wheels", "car summary"];
    var vehicle_category = {
      "370Z": "SPORTS CARS",
      ALTIMA: "PASSENGER CARS",
      CIVILIAN: "COMMERCIAL VEHICLES",
      KICKS: "SUVS & CROSSOVERS",
      URVAN: "COMMERCIAL VEHICLES",
      MAXIMA: "PASSENGER CARS",
      NAVARA: "COMMERCIAL VEHICLES",
      PATHFINDER: "SUVS & CROSSOVERS",
      PATROL: "SUVS & CROSSOVERS",
      "PATROL-PICKUP": "COMMERCIAL VEHICLES",
      "PATROL-SAFARI": "SUVS & CROSSOVERS",
      SENTRA: "PASSENGER CARS",
      SUNNY: "PASSENGER CARS",

      "X-TRAIL": "SUVS & CROSSOVERS",
      "GT-R": "SPORTS CARS",
      MICRA: "PASSENGER CARS",
      LEAF: "PASSENGER CARS",
      "X-TERRA": "SUVS & CROSSOVERS",
      JUKE: "SUVS & CROSSOVERS",
      MAGNITE: "SUVS & CROSSOVERS",
      NP200: "COMMERCIAL VEHICLES",
      "NEW MICRA": "PASSENGER CARS",
      QASHQAI: "SUVS & CROSSOVERS",
      ALMERA: "PASSENGER CARS",
      NP300: "COMMERCIAL VEHICLES",
    };

    var vehicle_id = {
      "370Z": "Z34C",
      ALTIMA: "L34",
      CIVILIAN: "W41",
      KICKS: "P15",
      URVAN: "E26",
      MAXIMA: "A36",
      NAVARA: "D23",
      PATHFINDER: "R52",
      PATROL: "Y62",
      "PATROL-PICKUP": "UY61",
      "PATROL-SAFARI": "Y61",
      SENTRA: "B17",
      SUNNY: "N18",
      "X-TRAIL": "T32",
      "GT-R": "R35",
      MICRA: "K13",
      LEAF: "ZE1",
      "X-TERRA": "P60A",
      JUKE: "JUKE",
      MAGNITE: "EM2",
      NP200: "UA90",
      "NEW MICRA": "K14",
      QASHQAI: "J11",
      ALMERA: "LO2B",
      NP300: "D22",
    };
    //var toolStart = false;
    var toolStart = false;
    s = window._satellite.getToolsByType("sc")[0].getS();
    s.account = "nissansastaging"; //account
    s.currencyCode = "SAR";
    const pageNumber = parseInt(data.currentpage) + 1;
    var uppercaseModel = data.model.toString().toUpperCase();
    if (data.currentpage == "0" && data.type == "load") {
      s.pageName = "vehicles|" + data.model + "|configure";
      s.eVar1 = "vehicles|" + data.model + "|configure";
      s.prop1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.eVar2 = "vehicles|" + data.model; //sub section
      s.prop2 = "vehicles|" + data.model; //sub section
      s.eVar3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.prop3 = "vehicles|" + data.model + "|configure"; //Sub sub section

      s.eVar4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.prop4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.eVar5 = "nissan " + data.model; //Vehicle model
      s.prop5 = "nissan " + data.model; //Vehicle model
      s.eVar6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop7 = data.model + "|configure"; //Vehicle ID
      // s.prop14 =
      //   "[button text]|[destination page]|[currentpagename]|[locationInpage]";

      s.prop14 =
        data.buttonText +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        data.location;

      s.prop29 = "configurator";
      s.eVar12 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.eVar28 = "configurator"; //Country code
      s.eVar29 = "configurator v2"; //Country code

      s.eVar97 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage];

      s.eVar60 = "";
      s.prop60 = "";
      s.events = "event11,event139";

      s.ta();
    } else if (data.currentpage == 0 && data.type == "versions") {
      load(data);
    } else if (data.type == "selection") {
      toolStart = true;
      const pNumber = parseInt(data.currentpage) + 1;
      s.pageName =
        "vehicles|" +
        data.model +
        "|configure|" +
        pNumber +
        ":" +
        pages[data.currentpage]; //pagename
      s.eVar1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pNumber +
        ":" +
        pages[data.currentpage];
      s.prop1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pNumber +
        ":" +
        pages[data.currentpage];
      s.eVar2 = "vehicles|" + data.model; //sub section
      s.prop2 = "vehicles|" + data.model; //sub section
      s.eVar3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.prop3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.eVar4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.prop4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.eVar5 = "nissan " + data.model; //Vehicle model
      s.prop5 = "nissan " + data.model; //Vehicle model
      s.eVar6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop7 = data.model + "|configure"; //Vehicle ID
      //s.eVar7 = data.object.price; //Model Page

      // s.prop14 =
      //   "[button text]|[destination page]|[currentpagename]|[locationInpage]";

      s.prop14 =
        data.buttonText +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        data.location;
      if (data.currentpage != 0) {
        s.prop28 = data.item_slection;
      } else {
        s.prop28 = "";
      }

      s.prop29 = "configurator";
      s.eVar12 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pNumber +
        ":" +
        pages[data.currentpage];

      s.prop44 =
        data.object.Version_Label +
        "|" +
        data.exteriorColor.name +
        "|" +
        data.interiorColor.name +
        "|" +
        data.wheel.name +
        "|" +
        data.object.price;

      // s.prop51 = pNumber + ":" + pages[data.currentpage];
      s.eVar26 = pages[data.currentpage] + "|" + data.item_slection; //Model Page
      s.eVar28 = "configurator"; //Model Page
      s.eVar29 = "configurator v2"; //Model Page
      s.prop33 = ""; //Language

      s.eVar97 = "";

      s.eVar60 = "";
      s.prop60 = "";
      if (toolStart) {
        s.events = "event26,event28,event139";
      } else {
        s.events = "event26";
      }

      s.ta();
      if (data.currentpage == 0) {
        data.currentpage = 1;
        data.buttonText = "nav-bar";
        data.location = "nav-bar";
        load(data);
      }
    } else if (data.type == "sort" || data.type == "in_ext") {
      console.log("pageNumber", pageNumber);
      s.pageName =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage]; //pagename
      s.eVar1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.prop1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.eVar2 = "vehicles|" + data.model; //sub section
      s.prop2 = "vehicles|" + data.model; //sub section
      s.eVar3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.prop3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.eVar4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.prop4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.eVar5 = "nissan " + data.model; //Vehicle model
      s.prop5 = "nissan " + data.model; //Vehicle model
      s.eVar6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop7 = data.model + "|configure"; //Vehicle ID
      //s.eVar7 = data.object.price; //Model Page

      // s.prop14 =
      //   "[button text]|[destination page]|[currentpagename]|[locationInpage]";

      s.prop14 =
        data.buttonText +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        data.location;

      s.prop29 = "configurator";
      s.eVar12 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage];

      s.prop44 = "";

      // s.prop51 = pageNumber + ":" + pages[data.currentpage];
      s.eVar26 = ""; //Model Page
      s.eVar28 = "configurator"; //Model Page
      s.eVar29 = "configurator v2"; //Model Page
      s.prop33 = "configurator |" + data.buttonText; //Language

      s.eVar97 = "";

      s.eVar60 = "";
      s.prop60 = "";

      s.events = "";

      s.ta();
    } else if (data.currentpage == "1" && data.type == "colours") {
      load(data);
    } else if (data.currentpage == "2" && data.type == "upholstery") {
      load(data);
    } else if (data.currentpage == "3" && data.type == "wheels") {
      load(data);
    } else if (data.currentpage == 4 && data.type == "complete") {
      s.pageName =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage]; //pagename
      s.eVar1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.prop1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pageNumber +
        ":" +
        pages[data.currentpage];
      s.eVar2 = "vehicles|" + data.model; //sub section
      s.prop2 = "vehicles|" + data.model; //sub section
      s.eVar3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.prop3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.eVar4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.prop4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.eVar5 = "nissan " + data.model; //Vehicle model
      s.prop5 = "nissan " + data.model; //Vehicle model
      s.eVar6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop6 = vehicle_id[uppercaseModel]; //Vehicle ID

      s.eVar7 = data.object.price; //Model Page

      // s.prop14 =
      //   "[button text]|[destination page]|[currentpagename]|[locationInpage]";

      s.prop14 =
        "nav-bar" +
        ":" +
        "|vehicles|" +
        data.model +
        "|" +
        "configurator" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configurator" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage] +
        "|nav-bar";

      if (data.type != "ext_int_toggle") {
        s.prop25 =
          data.model +
          "|" +
          data.object.Version_Label +
          "|" +
          data.object.price;
        s.prop27 =
          data.object.Version_Label +
          "|" +
          data.object.technical_specs.Engine_Type.values[0].value +
          "|" +
          data.exteriorColor.name +
          "|" +
          data.wheel.name +
          "|" +
          data.interiorColor.name +
          "|Automatic";

        s.eVar38 = data.exteriorColor.name + "|" + data.wheel.name; //Country code
        s.prop51 = pageNumber + ":" + pages[data.currentpage];
        s.prop53 = ""; //Language
        s.eVar64 = ""; //Language
        s.eVar97 =
          "vehicles|" +
          data.model +
          "|" +
          "configure" +
          "|" +
          pageNumber +
          ":" +
          pages[data.currentpage];
      } else {
        s.prop25 = "";
        s.prop27 = "";
        s.eVar38 = ""; //Country code
        s.prop51 = "";
        s.prop53 = ""; //Language
        s.eVar64 = ""; //Language
        s.eVar97 = "";
      }
      s.eVar60 = "";
      s.prop60 = "";

      // exteriorColour | wheel | upholstry | trim | equipment | stylePack | accessories";
      s.prop29 = "configurator";
      s.eVar12 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pageNumber +
        ":" +
        pages[data.currentpage];

      s.eVar28 = "configurator"; //Country code
      s.eVar29 = "configurator v2"; //Country code
      if (data.type == "ext_int_toggle") {
        s.prop33 = data.buttonText; //Language
      }

      if (data.type == "load") {
        s.events = "event139,event29";
      } else if (data.type == "complete") {
        s.events = "event12,event65,event29,event139";
      } else if (data.type == "save") {
        s.eVar16 = "save"; //Country code
        s.events = "event16,event65,event29";
      } else if (data.type == "reset") {
        s.events = "event17,event65,event29";
      } else if (data.type == "print") {
        s.events = "event18,event65,event29";
      } else if (data.type == "ext_int_toggle") {
        s.events = "event139";
      } else {
        s.eVar16 = ""; //Country code
      }

      s.ta();
    }
    function load(data) {
      const pN = parseInt(data.currentpage) + 1;
      console.log("pagenumber", pN);
      s.pageName =
        "vehicles|" +
        data.model +
        "|configure|" +
        pN +
        ":" +
        pages[data.currentpage]; //pagename
      s.eVar1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pN +
        ":" +
        pages[data.currentpage];
      s.prop1 =
        "vehicles|" +
        data.model +
        "|configure|" +
        pN +
        ":" +
        pages[data.currentpage];
      s.eVar2 = "vehicles|" + data.model; //sub section
      s.prop2 = "vehicles|" + data.model; //sub section
      s.eVar3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.prop3 = "vehicles|" + data.model + "|configure"; //Sub sub section
      s.eVar4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.prop4 = vehicle_category[uppercaseModel]; //Vehicle category
      s.eVar5 = "nissan " + data.model; //Vehicle model
      s.prop5 = "nissan " + data.model; //Vehicle model
      s.eVar6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop6 = vehicle_id[uppercaseModel]; //Vehicle ID
      s.prop7 = data.model + "|configure"; //Vehicle ID
      //s.eVar7 = data.object.price; //Model Page

      // s.prop14 =
      //   "[button text]|[destination page]|[currentpagename]|[locationInpage]";

      s.prop14 =
        data.buttonText +
        "|vehicles|" +
        data.model +
        "|" +
        "configurator" +
        "|" +
        pN +
        ":" +
        pages[data.currentpage] +
        "|" +
        "vehicles|" +
        data.model +
        "|" +
        "configurator" +
        "|" +
        pN +
        ":" +
        pages[data.currentpage] +
        "|" +
        data.location;

      s.prop28 = "";
      s.prop29 = "configurator";
      s.eVar12 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pN +
        ":" +
        pages[data.currentpage];

      s.prop44 = "";

      // s.prop51 = pN + ":" + pages[data.currentpage];
      s.eVar26 = ""; //Model Page
      s.eVar28 = "configurator"; //Model Page
      s.eVar29 = "configurator v2"; //Model Page
      s.prop33 = ""; //Language

      s.eVar97 =
        "vehicles|" +
        data.model +
        "|" +
        "configure" +
        "|" +
        pN +
        ":" +
        pages[data.currentpage];

      s.eVar60 = "";
      s.prop60 = "";

      s.events = "event139";

      s.ta();
    }
  },
};
